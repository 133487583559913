/* @font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter.ttf)
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.align-middle {
  @apply justify-center items-center;
}

.border-input {
  border-bottom: solid 1px rgb(31, 41, 55) !important;
  border-radius: 0px;
}

.dark .border-input {
  border-bottom: solid 1px gray !important;
  border-radius: 0px;
}

.border-input:focus-visible {
  border-bottom: solid 1px rgb(31, 41, 55) !important;
  border-radius: 0px;
}

.float-label {
  display: flex;
  flex-direction: column;
  position: relative;
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

.float-label input {
  width: 100%;
  padding-top: 30px;
}

.float-label p {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 34px) scale(1);
  font-size: larger;
  padding-left: 5px;
}

.float-label:focus-within p,
.float-label .active {
  transform: translate(-2px, 5px) scale(0.8);
}

.Toastify {
  font-size: 14px;
}

.Toastify .Toastify__toast-container {
  width: 400px;
}

@media screen and (max-width: 768px) {
  .Toastify .Toastify__toast {
    width: 90vw;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Toastify__toast-theme--dark {
  background: #161d27 !important;
}

.qr-reader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}